@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #0f172b;
}

/* Firefox */
/* For Firefox 64+ */
* {
  scrollbar-color: transparent;
  scrollbar-width: thin;
}
.new_scroll {
  scrollbar-width: auto;
}

.no_scroller {
  scrollbar-width: auto;
}
/* Chrome, Safari, and Opera */
.no_scroller::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.no_scroller::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 8px;
  width: 0;
}

/* For Firefox 63 and below */
* {
  scrollbar-color: transparent;
}

/* Microsoft Edge and IE */
* {
  -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge */
}

*::-webkit-scrollbar {
  width: 6px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.phone1 {
  animation-name: phone1-move;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes phone1-move {
  0% {
    transform: translateY(30%);
    -webkit-transform: translateY(30%);
    -moz-transform: translateY(30%);
    -ms-transform: translateY(30%);
    -o-transform: translateY(30%);
  }
  100% {
    transform: translateY(-60%);
    -webkit-transform: translateY(-60%);
    -moz-transform: translateY(-60%);
    -ms-transform: translateY(-60%);
    -o-transform: translateY(-60%);
  }
}

.phone2 {
  animation-name: phone2-move;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes phone2-move {
  0% {
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%);
  }
  100% {
    transform: translateY(60%);
    -webkit-transform: translateY(60%);
    -moz-transform: translateY(60%);
    -ms-transform: translateY(60%);
    -o-transform: translateY(60%);
  }
}

.full_width {
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  margin-right: calc((100% - 100vw) / 2);
}
.inv:hover {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.bus:hover {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.sub:hover {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}
